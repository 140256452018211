<template>
    <div>
        <div class="is-main f-mb15">
            <div class="container">
                <section class="m-location f-mb10">
                    <span>当前位置：</span>
                    <a href="/">网站首页</a> &gt;
                    <a href="/content/channel/5fe009923db32aab1c7b23c7/">文章详情</a>
                </section>

                <el-dialog
                    title="微信支付"
                    :visible.sync="centerDialogVisible"
                    width="252px"
                    center
                    :destroy-on-close="true">
                    <el-card :body-style="{ padding: '0px' }">
                        <div ref="qrcode" id="qrcode"></div>
                    </el-card>
                </el-dialog>

                <div class="m-pgpdbox1">
                    <div class="m-detailbox">
                        <h1 class="u-lgtit text-center f-mb15 f-md-mb10">{{ articleDetail.title }}</h1>
                        <div class="m-dtfuns f-clearfix">
                            <div class="u-wzinfo f-fl f-md-fn">
                                <span>发布时间：{{ articleDetail.time }}</span>
                                <span>作者：{{ articleDetail.author }}</span>
                                <span>浏览：{{ articleDetail.views }} 次</span>
                            </div>
                            <div class="share-main f-fr">
                                <div class="share-bar j-share">
                                    <!--                                    <a class="share-tsina" data-type="tsina" title="分享到新浪微博" href="javascript:;"></a>-->
                                    <!--                                    <a class="share-weixin" data-type="weixin" title="分享到微信" href="javascript:;"></a>-->
                                    <!-- <el-button v-if="articleDetail.wxbuy === 1" type="success" size="small" @click="showImgBuy">立即购买</el-button> -->
                                </div>
                                <!--                                <div class="weixin-share-open">-->
                                <!--                                    <div class="weixin-wrap">-->
                                <!--                                        <div class="share-qrcode" id="j-wxqrcode"></div>-->
                                <!--                                        <div class="weixin-text">-->
                                <!--                                            <p>用微信扫描二维码<br>分享至好友和朋友圈</p>-->
                                <!--                                        </div>-->
                                <!--                                        <span class="weixin-close">×</span>-->
                                <!--                                    </div>-->
                                <!--                                </div>-->
                            </div>
                            <!--                            <div class="u-fontsize f-fr f-md-fn">-->
                            <!--                                【字体大小：-->
                            <!--                                <a href="javascript:doZoom(20)">大</a>-->
                            <!--                                <a href="javascript:doZoom(16)">中</a>-->
                            <!--                                <a href="javascript:doZoom(12)">小</a>】-->
                            <!--                            </div>-->
                        </div>
                        <div class="m-dtfonts content-box" :class="{'has-pay': articleDetail.paid}">
                            <div class="m-dttexts f-clearfix j-fontContent" id="zoom"
                                 v-html="articleDetail.detail"></div>
                            <div class="content-mask" v-if="!articleDetail.paid">
                              <span @click="showImgBuy">支付9.9元可阅读文章剩余内容,<el-button type="text">去支付</el-button></span>
                            </div>
                        </div>
                        <div class="m-dtdownload f-mb15">
                            <ul>
                            </ul>
                        </div>
                        <div class="m-btfuns f-clearfix f-mb15">
                            <ul class="f-fr">
                                <li class="u-gotp j-goTop">
                                    <a :href="item" target="_blank" v-for="(item, index) in articleDetail.files"
                                       :key="index">
                                        {{ '附件' + getFilesName(item) }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="m-dtsxqh f-clearfix" v-if="false">
                            <ul>
                                <li class="f-fl u-dtprev" @click="handlePrev">
                                    上一条：
                                    <a v-if="prev != null">{{ prev.title }}</a>
                                    <a v-else>没有上一条了</a>
                                </li>
                                <li class="f-fr u-dtnext" @click="handleNext">
                                    下一条：
                                    <a v-if="next != null">{{ next.title }}</a>
                                    <a v-else>没有下一条了</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as api from '@/api/api'
import QRCode from 'qrcodejs2'
export default {
    name: "detail",
    data() {
        return {
            articleDetail: {},
            next: {},
            prev: {},
            centerDialogVisible: false,
            codeUrl: '',
            timer: null,
            paid: false
        }
    },
    created() {
        this.activeId = this.$route.params.id
        this.getArticleDetail(this.activeId)
    },
    methods: {
      // 获取文章详情
        getArticleDetail(id) {
            const cmsToken = JSON.parse(sessionStorage.getItem("SET_TOKEN"))
            api.articleItem({articleId: id}, {cmsToken: cmsToken}).then(res => {
                let head = document.getElementsByTagName("head")    // 修改meta
                let meta = document.createElement("meta")
                meta.content = res.description

                if (this.$store.state.meta != null) {
                    head[0].replaceChild(meta, this.$store.state.meta)
                } else {
                    head[0].appendChild(meta)
                }
                this.$store.state.meta = meta


                document.title = res.title      // 修改title
                this.articleDetail = res
                if (this.articleDetail.files != null) {
                    let parse = JSON.parse(this.articleDetail.files);
                    this.articleDetail.files = (parse && Array.isArray(parse) && parse.length> 0)  ? parse : null
                }
                this.next = res.next
                this.prev = res.prev
            })
        },
        handleNext() {
            // this.getArticleDetail(this.next.id)
            this.$store.commit('SET_ACTIVEARTIVLEID', this.next.id)
            this.$router.push({
                path: `/detail/${this.next.id}`
            })
        },
        handlePrev() {
            // this.getArticleDetail(this.prev.id)
            this.$store.commit('SET_ACTIVEARTIVLEID', this.prev.id)
            this.$router.push({
                path: `/detail/${this.prev.id}`
            })
        },
        // 生成支付二维码
        getCoed() {
          if (this.$refs.qrcode) {
              new QRCode('qrcode', {
              width: 200,  // 宽
              height: 200, // 高
              text: this.codeUrl, // 二维码内容,也可以直接放接口获取的数据
            // render: 'canvas'     // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
            // background: '#f0f'   // 自定义颜色默认黑白
            // foreground: '#ff0'
            })
          }
          
        },
        // 获取支付二维码url
        async showImgBuy() {
            const item = sessionStorage.getItem("SET_USERINFO");
            if (!item) {
                this.$message.error('请您登录后再购买！');
                return;
            }
            const cmsToken = JSON.parse(sessionStorage.getItem("SET_TOKEN"))
            await api.payUrl({articleId: this.activeId}, {cmsToken: cmsToken}).then(res => {
              this.codeUrl = res
            })
            this.centerDialogVisible = true
            this.$nextTick(() => {
              this.getCoed()
              this.timer = setInterval(() => {
                if (this.paid) {
                  clearInterval(this.timer)
                  this.centerDialogVisible = false
                  this.paid = false
                  this.getArticleDetail(this.activeId)
                  return
                }
                this.getPayResult()
              }, 1000)
            })
        },
        // 获取支付结果
        getPayResult () {
          const cmsToken = JSON.parse(sessionStorage.getItem("SET_TOKEN"))
          api.payResult({articleId: this.activeId}, {cmsToken: cmsToken}).then(res => {
            this.paid = res
          })
        }
    },
    computed: {
        getFilesName() {
            return item => {
                return item.substring(item.length - 25)
            }
        }
    },
    watch: {
        '$store.state.activeArticleId': function (newValue) {
            this.getArticleDetail(newValue)
        }
    }
}
</script>

<style scoped>
.content-box {
  position: relative;
  max-height: 600px;
  overflow: hidden;
}
.has-pay {
  max-height: none;
}
.content-mask {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 210px;
  width: 100%;
  padding-top: 50px;
  background: linear-gradient(rgba(255,255,255,0.8) 0, rgba(255,255,255,1) 50%);
  text-align: center;
}
.content-mask span {
  cursor: pointer;
}

</style>
