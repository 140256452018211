import request from '@/api/request'

export function siteItem(data) {
    return request({url: '/site/common/item', method: 'post', data})
}

export function cateTree(data) {    // 文章分类树形
    return request({url: '/cate/common/tree', method: 'post', data})
}

export function updateRecommend(data) {
    return request({url: '/site/updateRecommend', method: 'post', data})
}

export function updateBanner(data) {
    return request({url: '/site/updateBanner', method: 'post', data})
}

export function articlePage(data) {   // 文章分页
    return request({url: '/article/common/page', method: 'post', data})
}

export function articleItem(data, headers) {   // 文章单条
    return request({url: '/article/common/item', method: 'post', data, headers})
}

export function indexData(data) {   // 主页
    return request({url: '/index/common/index', method: 'post', data})
}


export function cateArticle(data) {   // 一级分类下的文章
    return request({url: '/article/common/cateArticle', method: 'post', data})
}

export function payUrl(data, headers) { // 请求支付二维码url
  return request({url: '/pay/order', method: 'post', data, headers})
}

export function payResult(data, headers) { // 请求支付结果
  return request({url: '/article/common/item/check', method: 'post', data, headers})
}

export function login(data) {   // 用户登陆
    return request({
        url: '/user/auth/login',
        method: 'post',
        data
    })
}

export function register(data) {   // 用户注册
    return request({
        url: '/user/auth/register',
        method: 'post',
        data
    })
}
